import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import FileSaver from "file-saver";
import {useParams} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";
import { format, parseISO } from 'date-fns';
import copy from "copy-to-clipboard"

const Detail = () =>{
   const history = useHistory();
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = (url) => {
       setVideoUrl(url);
       setShow(true)
   }
   const [backImage, setBackImage] = useState('')

   const [videoUrl, setVideoUrl] = useState('https://iansportalimages.s3.amazonaws.com/videos_watermark/28-09-2023-11.mp4');
   const videoStartPlay = (url)=>{
       setVideoUrl(url);
   }   
const {newsId} = useParams();
const [eventData, setEventsData] = useState();

const [textToCopy, setTextToCopy] = useState('')
const [isLoading, setIsLoading] = useState(false);
const [collectionData, setCollectionData] = useState([]);
const [tgData, setTagsData] = useState([]);
const [login, setLogin] = useState(true)
const [errorMsg, setErrorMsg] = useState('');
const [latData, setLatestData] = useState({});
const [photoCollectionData, setPhotophotoCollectionData] = useState('[]');

const getEventData =(newsId)=>{
   axios
   .get(
   `https://cms.iansnews.in/api/elastic/imagenews/detail/${newsId}/?language=english`
   )
   .then((res) => {
       setCollectionData({
           dateTime: res.data.results &&
           res.data.results.length > 0 && 
           res.data.results[0].created_at ? formatDate(res.data.results[0].created_at): '',
           title : res.data.results &&
           res.data.results.length > 0 && 
           res.data.results[0].title ? res.data.results[0].title: '',
           tags : res.data.results &&
           res.data.results.length > 0 && 
           res.data.results[0].tags ? res.data.results[0].tags: ''
       });
   const latestStories =
       res.data.results &&
       res.data.results.length > 0 &&
       res.data.results[0].images_thumbnails.length > 0 &&
       res.data.results[0].images_thumbnails.map((item) => {

       return {
           content: item.caption,
           id: item.id,
           image: item.image ? item.image : 'https://iansportalimages.s3.amazonaws.com/all/ians_df_img.jpg',
           title: item.title,
           slug:'/detail/'+item.slug
       }});
       setEventsData(latestStories)
   }).catch(err => {
      console.log('Google api calendar error', err) 
      if(err && err.response && err.response.data && err.response.data.detail
      ){if(err.response.data.detail == 'Invalid token header. No credentials provided.'){
         setErrorMsg(`If you are a subscriber, please login to view this page. Otherwise please contact IANS marketing team.`)
         //history.push('/permision');
      }else if(err.response.data.detail && err.response.data.detail.length > 0 ){
         setErrorMsg(err.response.data.detail)
         //history.push('/permision');
      }
      }})
}
const LatestData =()=>{
   axios
   .get(
   `https://cms.iansnews.in/api/elastic/imagenews/list/?top_news=true`
   )
   .then((res) => {
   const latestStories =
       res.data.results &&
       res.data.results.length > 0 &&
       res.data.results.slice(0, 8).map((item) => {
         let tgSt= item.tags.filter(it => it.name === 'Business')

       return {
           byline: item.byline,
           content: item.short_desc,
           id: item.id,
           image: item.image,
           title: item.title,
           slug:'/event/'+item.slug,
           dateTime: item.created_at ? formatDate(item.created_at): '',
           slugType: tgSt && tgSt.length > 0 ? true : false,
           image_caption: item.image_caption,
       };
       });
   setLatestData(latestStories);
   });
}
const formatDate = (timestamp)=>{
   const date = parseISO(timestamp);
   return format(date, 'MMMM dd, yyyy h:mm a');
}

  const imageDownload=(url)=>{
   setIsLoading(true);
            let modifiedUrl = url.split('/').pop();
          // downloadResource(url, modifiedUrl);
          if (!modifiedUrl) modifiedUrl = url.split('\\').pop().split('/').pop();
          let updateUrl = url.replace("videos_watermark", "videos");
          fetch(updateUrl)
          .then(response => response.blob())
          .then(blob => {
            console.log('ÍANSSS',blob);
            if(blob && blob.size && blob.size > 1000){
              setIsLoading(false);
            }
              const fileSaver = FileSaver.saveAs(blob, modifiedUrl);
              fileSaver.onwriteend = function () {
              console.log('Download completed.');
              // alert();
             setIsLoading(false);
              };
              fileSaver.onerror = function () {
              console.error('Download failed.');
              };
          })
          .catch(e => console.error(e));
         
  }
  const getData = async() =>{
 
   const apiD =`https://cms.iansnews.in/api/elastic/image/detail/${newsId}/?language=english`
   const config = {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
      },
    };
   axios.get(apiD, config)
.then(res =>
  {
   let main_content_string = res.data.results[0].content;
   let iansIndex =  main_content_string;
   let new_main_content_string = '';

    
   let imgByLine = '';
   if(res.data.results[0].byline =='ians'){
      imgByLine = 'https://iansportalimages.s3.amazonaws.com/all/ians-author.jpg';
   }else{
       if(res.data.results[0] && res.data.results[0].byline){
           let newIi = res.data.results[0].byline;
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
     
           newIi = newIi.toLowerCase();
           imgByLine = 'https://iansportalimages.s3.amazonaws.com/author/'+newIi+'.jpg';
       }

   }
  
   setPhotophotoCollectionData({
      caption:res.data.results[0].caption,
      content: '<p>'+res.data.results[0].short_desc +'</p>'+ new_main_content_string,
      copy_content: res.data.results[0].caption,
      id: res.data.results[0].id,
      image: res.data.results[0].thumbnail,
      title: res.data.results[0].title,
      images_thumbnails: res.data.results[0].images_thumbnails,
      url: res.data.results[0].url,
      dateTime: res.data.results[0].created_at ? formatDate(res.data.results[0].created_at): '',
      slug:'/'+res.data.results[0].slug,
      image_caption: res.data.results[0].image_caption
 })
 setBackImage(`background: url(${res.data.results[0].thumbnail}) no-repeat 50% 50%`)

 const tagData = res
 && res.data 
 && res.data.results 
 && res.data.results.length > 0 
 && res.data.results[0] 
 && res.data.results[0].tags 
 && res.data.results[0].tags.length > 0 
 && res.data.results[0].tags.map((taggg,tt) => {
  return {
    id: taggg.id,
    name: taggg.name,
    slug: taggg.slug
  }
})
   console.log("Your new array of modified objects here",photoCollectionData)
setTagsData(tagData);
})
.catch(err => {
   console.log('Google api calendar error', err) 
   if(err && err.response && err.response.data && err.response.data.detail
   ){if(err.response.data.detail == 'Invalid token header. No credentials provided.'){
      setErrorMsg(`If you are a subscriber, please login to view this page. Otherwise please contact IANS marketing team.`)
      //history.push('/permision');
   }else if(err.response.data.detail && err.response.data.detail.length > 0 ){
      setErrorMsg(err.response.data.detail)
      //history.push('/permision');
   }
   }})
}
  const getccData = async() =>{
 
   const apiD =`https://cms.iansnews.in/api/elastic/image/detail/${newsId}/?language=english
   `
   const config = {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
      },
    };
   axios.get(apiD, config)
.then(res =>
  {
   let main_content_string = res.data.results[0].content;
   let iansIndex =  main_content_string;
   let new_main_content_string = '';

    
   let imgByLine = '';
   if(res.data.results[0].byline =='ians'){
      imgByLine = 'https://iansportalimages.s3.amazonaws.com/all/ians-author.jpg';
   }else{
       if(res.data.results[0] && res.data.results[0].byline){
           let newIi = res.data.results[0].byline;
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
     
           newIi = newIi.toLowerCase();
           imgByLine = 'https://iansportalimages.s3.amazonaws.com/author/'+newIi+'.jpg';
       }

   }
  
   setCollectionData({
   caption:res.data.results[0].caption,
   content: '<p>'+res.data.results[0].short_desc +'</p>'+ new_main_content_string,
   copy_content: res.data.results[0].content,
   id: res.data.results[0].id,
   image: res.data.results[0].thumbnail,
   title: res.data.results[0].title,
   images_thumbnails: res.data.results[0].images_thumbnails,
   url: res.data.results[0].url,
   dateTime: res.data.results[0].created_at ? formatDate(res.data.results[0].created_at): '',
   slug:'/'+res.data.results[0].slug,
   image_caption: res.data.results[0].image_caption
 })
 
 const tagData = res
 && res.data 
 && res.data.results 
 && res.data.results.length > 0 
 && res.data.results[0] 
 && res.data.results[0].tags 
 && res.data.results[0].tags.length > 0 
 && res.data.results[0].tags.map((taggg,tt) => {
  return {
    id: taggg.id,
    name: taggg.name,
    slug: taggg.slug
  }
})
   console.log("Your new array of modified objects here",collectionData)
setTagsData(tagData);
})
.catch(err => {
 console.log('Google api calendar error', err) 
 if(err && err.response && err.response.data && err.response.data.detail
   ){if(err.response.data.detail == 'Invalid token header. No credentials provided.'){
      setErrorMsg(`If you are a subscriber, please login to view this page. Otherwise please contact IANS marketing team.`)
   }else{
      setErrorMsg(err.response.data.detail)
   }
 }})
}
const copyToText = async () => {
   const tempDiv = document.createElement('div')
   tempDiv.innerHTML = photoCollectionData.copy_content

   // Remove most HTML tags except for <p>
   let copyt = ''

   // Replace specific <p> tags with double line breaks
   //copy = copy.replace(/<p><\/p>/g, '\n\n');

   // Keep specific <p> tags at the bottom
   const specificTags = tempDiv.querySelectorAll('p')
   specificTags.forEach((tag) => {
      copyt += '\n\n' + tag.innerHTML;
   });

   // Combine title, short_desc, and modified content
   let final_copy = '';
   //if(final_copy){
      final_copy = `Title : ${photoCollectionData.title}\n\n Caption : ${photoCollectionData.caption} ${copyt}`
      final_copy=final_copy.replace("<br>","\n")
      final_copy=final_copy.replace('<br>','\n')
      final_copy = final_copy.replace(/&nbsp;/g, ' ')
      final_copy=final_copy.replace('<br data-mce-bogus="1">','')
      copy(final_copy);
      // handleSubmit(collectionData.title, collectionData.id)
   //}
   // Copy the final content to the clipboard
   //await navigator.clipboard.writeText(final_copy);
   }
   const [isVisible, setIsVisible] = useState(false);

   const scrollToTop = () => {
       window.scrollTo({
         top: 0,
         behavior: "smooth"
       });
     };   
   const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
 useEffect(() => {
   if(localStorage && localStorage.accessToken){
      setLogin(false)
   }else{
      setLogin(true)
   }
   let tempId = [];
   if(newsId){
tempId = newsId.split("-id-")
if(tempId && tempId.length > 0){
   getEventData(tempId[0])
}
}
   if(!localStorage || localStorage.length == 0 || !localStorage.redirectTo){
      localStorage.setItem('redirectTo', newsId ? '/'+newsId :'');
      history.push('/login');
  }

   LatestData();
   getData();
   scrollToTop();


   window.addEventListener("scroll", toggleVisibility);
 
   return () => window.removeEventListener("scroll", toggleVisibility);
}, [newsId])

    return(
        <>
         <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charSet=utf-8" />
            <title>Indo-Asian News Service-{collectionData.title ? collectionData.title.toUpperCase() : ''}</title>
            <meta data-meta-title="IANS IN" name="keywords" content={collectionData.keywords ? collectionData.keywords.toUpperCase() : ''} />
            <meta data-meta-title="IANS IN" name="description" content={collectionData.short_desc} />
            <meta property="og:title" content={collectionData.title} />
            <meta property="og:description" content={collectionData.short_desc} />
            <meta property="og:image" content={collectionData.image} />
            <meta property="og:url" content={'https://iansphoto.in/detail'+collectionData.slug} />
            <meta property="og:type" content="article" />
        </Helmet>
        
         <Header show={login}/>
        
         <section className="position-relative gen-section-padding-3">
            <div className="container">
               <div className="row">
                     <div className="col-lg-12">
                        <div className="gen-tv-show-wrapper style-1">
                              <div className="gen-tv-show-top">
{     !errorMsg &&        
                                 <div className="row">
                                    <div className="col-lg-6">
                                          <div className="gentech-tv-show-img-holder detail-page">
                                             <img src={photoCollectionData.image} alt="stream-lab-image"/>
                                    <div className="download-img" onClick={()=>{
                                       imageDownload(photoCollectionData.url)
                                    }}><i className="fa fa-download"> </i></div>
                                          </div>
                                    </div>
                                    <div className="col-lg-6 align-self-center">
                                          <div className="gen-single-tv-show-info">
                     
                                 <h2 className="gen-title">{photoCollectionData.title}</h2>
                                 <p><b>Photo ID: </b>{photoCollectionData.id} </p>
                                 <p><b>Caption:</b> {photoCollectionData.caption}</p>
                                 <p><b>Release Date &amp; Time: </b>{photoCollectionData.dateTime}</p>
                                 {/* <p><b>Source: </b>IANS </p> */}
                                 <p><b>Tags:</b> <span data-tags="">{tgData && tgData.length > 0 && tgData.map((tgg, t)=>{
                                    return(
                                       <>
                                       <span key={t}> {tgg.name} </span>
                                       
                                       </>
                                    )
                                 })}, </span></p>
                           </div>
                                    </div>
                         
                                 </div>
                                 }
{        errorMsg &&                   
                                 <div className="row">
                           <div className="col-lg-12 market-01">
                              <div className="warning-msg">
                              <i className="fas fa-exclamation-triangle"></i>
                              Your don't have permission to access this category. Please contact IANS marketing team.
                              </div>
                              <div className="col-lg-12 mb-5 dbox-a ">
                              <div className="row">
                              <div className="col-md-4">
                              <div className="alrt-dbox w-100 text-center">
                              <div className="icon d-flex align-items-center justify-content-center">
                              <span className="fa fa-map-marker"></span>
                              </div>
                              <div className="text">
                              <p><span>Address:</span>IANS India Private Limited, A-6, Ground Floor,
                              Block A, Sector-16, Noida, Uttar Pradesh - 201301</p>
                              </div>
                              </div>
                              </div>
                              <div className="col-md-4">
                              <div className="alrt-dbox w-100 text-center">
                              <div className="icon d-flex align-items-center justify-content-center">
                              <span className="fa fa-phone"></span>
                              </div>
                              <div className="text">
                              <p><span>Phone:</span> <a href="tel://+91-120-4822400">+91-120-4822400,</a> <a href="tel://+91-120-4822415">4822415,</a> <a href="tel://+91-120-4822416">4822416</a></p>
                              </div>
                              </div>
                              </div>
                              <div className="col-md-4">
                              <div className="alrt-dbox w-100 text-center">
                              <div className="icon d-flex align-items-center justify-content-center">
                              <span className="fa fa-paper-plane"></span>
                              </div>
                              <div className="text">
                              <p><span>Email:</span> <a href="mailto: dakul.s@ians.in">dakul.s@ians.in,</a> <a href="mailto: marketing@ians.in">marketing@ians.in</a></p>
                              </div>
                              </div>
                              </div>
                              </div>
                              </div>
                           </div>
                                 </div>
                                 }
                              </div>
                              
                        </div>
                     </div>
                  </div>

                  { !login &&               
                  <div className="row">
                     <div className="col-lg-12">
                        <div className="pm-inner">
                           <div className="gen-more-like">
                              <h5 className="gen-more-title">Related Images</h5>
                              <div className="row post-loadmore-wrapper">
                                 {eventData && eventData.length > 0 && eventData.map((recentEvent, i)=>{
                                    return(
                                       <div className="col-xl-3 col-lg-4 col-md-6 mb-3" key={i}>
                                       <NavLink to={recentEvent.slug}>
                                          <div className="thumb w-100  album-thumb p-4">
                                             <img className="thumb-image w-100" src={recentEvent.image}/>
                                             <div className="text-l-gray des-title mt-3">{recentEvent.title}</div>
                                             {/* <div className="label text-white label-photo">{recentEvent.dateTime}</div> */}
                                          </div>
                                       </NavLink>
                                    </div>                                    
                                    )
                                 })}
                              </div>
                           </div>
                        </div>
                     </div>
                   
                  </div>
                  }
            </div>
         </section>         
         <Footer/>       
        </>
    )
}

export default Detail