import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";
import { useLocation, Redirect } from 'react-router-dom';

const Login = ()=>{
    const [collectionData, setCollectionData] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const[inputData, setInputData] = useState({
        username:'',
        password:''
    })
    const history = useHistory();
    const location = useLocation();

    const { from } = location.state || { from: { pathname: '/' } };

      const handleOnChange = event => {
        const { name, value } = event.target;
        setInputData({ ...inputData, [name]: value });
      };
        const loginUser = (e) => {


        e.preventDefault()
        if(!inputData.username){
            setErrorMsg('Email can not be blank');
        }else if(!inputData.password){
            setErrorMsg('Password can not be blank');

        }else{
            let formData = new FormData();

            formData.append('username', inputData.username);
            formData.append('password', inputData.password);
            axios.post(`https://cms.iansnews.in/api/login/`,inputData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
            if(response && response.data && response.data.status){
                localStorage.setItem('accessToken', response.data.token? response.data.token :'');
                localStorage.setItem('userName', response.data.name? response.data.name:'');
                // if(localStorage && localStorage.redirectTo){
                //     history.push(localStorage.redirectTo);
                // }else{
                //     history.push('/');
                // }
                if(localStorage && localStorage.redirectTo){
                    window.location.href = '/detail'+localStorage.redirectTo;
                    //history.push('/detail'+localStorage.redirectTo);
                   // window.reload()
                }else{
                    history.push('/');
                }
            }

            console.log(response, 'rrrrrrrrrrr');
                        setErrorMsg(response.data.message);

            }).catch(err =>{
                console.log(err,'eeeee');
                setErrorMsg(err.response.data.message);
            })            

        }

        // console.log(conFom)
      }
      useEffect(() => {
        if(localStorage && localStorage.accessToken){
            history.push('/');
        }
        <Redirect to={from} />
      }, []);
    return(
        <>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charSet=utf-8" />
            <title>Indo-Asian News Service</title>
            <meta data-meta-title="IANS IN" name="keywords" content='' />
            <meta data-meta-title="IANS IN" name="description" content='' />
            <meta property="og:title" content='' />
            <meta property="og:description" content='' />
            <meta property="og:image" content='' />
            <meta property="og:url" content={'https://ians.in/detail'} />
            <meta property="og:type" content="article" />
        </Helmet>
         <Header />
        <section className="cont-sec">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-12">
                        <div className="wrapper">
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="section-heading">
                                        <h2>Sign In</h2>
                                        <span className="login-error-msg">{errorMsg}</span> 
                                    </div>
                                </div>
                                
                                <div className="col-lg-12">
                            <div className="text-center">
                                <form onSubmit={loginUser} name="pms_login" id="pms_login">
                                    
                                    <p className="login-username">
                                        <label for="user_login">Username or Email Address</label>
                                        <input type="text" className="input" name="username" onChange={handleOnChange} value={inputData.username} size="20"/>
                                    </p>
                                    <p className="login-password">
                                        <label for="user_pass">Password</label>
                                        <input type="password"  className="input" name="password" onChange={handleOnChange} value={inputData.email} size="20"/>
                                    </p>
                                    {/* <p className="login-remember">
                                        <label>
                                            <input name="rememberme" type="checkbox" id="rememberme" value="forever"/> Remember
                                            Me </label>
                                    </p> */}
                                    <p className="login-submit">
                                        <button type="submit" name="wp-submit" id="wp-submit" className="button button-primary"
                                            >Log In</button>
                                        
                                    </p>
                                    
                                </form>
                            </div>
                        </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </section>         
         <Footer />
        </>
    )
}
export default Login