import React from 'react'
import {NavLink} from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

const Footer=()=>{
  const year = new Date().getFullYear();

    return (
        <>
          <footer id="gen-footer">
              <div className="gen-footer-style-1">
                  <div className="gen-footer-top">
                      <div className="container">
                          <div className="row">
                              <h4 className="footer-title">Explore the IANS</h4>
                                <ul>
                                  <li><a href="/" target="_blank">Home</a></li>
                                  <li><a href="https://ians.in/" target="_blank">IANS English News</a></li>
                                  <li><a href="https://ianshindi.in/" target="_blank">IANS Hindi News</a></li>
                                  <li><a href="https://www.iansphoto.in/" target="_blank">IANS Photo</a></li>
                                  <li><a href="https://www.iansvideo.com/" target="_blank">IANS Video</a></li>
                                  <li><a href="https://ianstv.in" target="_blank">IANS TV</a></li>
                                  <li><a href="https://ianslife.in/" target="_blank">IANS Life</a></li>
                                  <li><a href="https://ianslive.in/" target="_blank">IANS Live</a></li>
                                  <li><a href="https://business.ians.in/" target="_blank">IANS Business</a></li>
                                  <li><a href="https://bollywoodcountry.in/" target="_blank">Bollywood Country</a></li>
                                  <li><a href="https://www.cricketfanatic.in/" target="_blank">Cricket Fanatic</a></li>
                                  <li><a href="#" target="_blank">Khabar Filmy</a></li>
                            </ul>
                          </div>
                      </div>
                  </div>
                  
        
                  <div className="gen-copyright-footer">
                      <div className="container">
                          <div className="row">
                              <div className="col-md-12 align-self-center">
                                  <span className="gen-copyright"><a target="_blank" href="#"> Copyright © 2022 Indo-Asian News Service (IANS) India Private Limited.</a><a href="http://ianssoftware.com/" target="_blank"> Design and Development IANS Software Services</a></span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </footer>
          <div id="back-to-top">
              <a className="top" id="top" href=""> <i className="ion-ios-arrow-up"></i> </a>
          </div>
        </>
    )
}
export default Footer