import React from 'react'
import Slider from "react-slick"
import axios from 'axios';
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {NavLink} from 'react-router-dom';
export default function PortfolioPageSlider(props) {
    var settings = {

      autoplay: false,
      autoplaySpeed: 1000,
      slidesToShow: 4,
      // dots: true,
      arrows: true,
      responsive: [
     {
       breakpoint: 458,
       settings: {
       slidesToShow: 1,
       slidesToScroll: 1
       }
     }
    ]
      };
      return (
        <>
              <section className="upcoming-meetings" id="latest">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading">
                                <h2>{props && props.name ? props.name : ''}</h2>
                            </div>
                        </div>
                        <Slider {...settings} className="slick-slider2">
                          {props && props.data && props.data.length > 0 && props.data.map((item, i)=>(

                        <>
                                 
                          <div className="item" key={i}>
                          <div className="movie type-movie status-publish has-post-thumbnail hentry movie_genre-action movie_genre-adventure movie_genre-drama">
                            <div className="gen-carousel-movies-style-2 movie-grid style-2">
                              <div className="thumb album-thumb">
                                <div className="thumb-container">
                                <NavLink to={item.slug} title={item.title}>
                                  <div className="images-container">
                                    <img className="thumb-image" src={item.image}/>
                                  </div>
                                  </NavLink>
                                  <div className="photo-count">
                                    <div className="content">
                                      <div className="number">{item.image_count}</div>
                                      <div className="label">PHOTOS</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="title"><NavLink to={item.slug} title={item.title}>{item.title}</NavLink></div>
                                <div className="label text-white">{item.dateTime}</div>
                              </div>
                            </div>
                          </div>
                        
                        </div>
                        </>                                         
                          ))}
                        </Slider>
                     </div>
                </div>
            </section>  
        </>

      );
    }
