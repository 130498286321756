import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";
import { format, parseISO } from 'date-fns';
import AdMultiplex from './AdMultiplex';
const Event =()=>{
 const history = useHistory();
 const {newsId} = useParams();
 const [collectionData, setCollectionData] = useState({});
 const [login, setLogin] = useState(false)
 const [latData, setLatestData] = useState();
 const LatestData =()=>{
    axios
    .get(
    `https://cms.iansnews.in/api/elastic/imagenews/detail/${newsId}/?language=english`
    )
    .then((res) => {
        setCollectionData({
            dateTime: res.data.results &&
            res.data.results.length > 0 && 
            res.data.results[0].created_at ? formatDate(res.data.results[0].created_at): '',
            title : res.data.results &&
            res.data.results.length > 0 && 
            res.data.results[0].title ? res.data.results[0].title: '',
            tags : res.data.results &&
            res.data.results.length > 0 && 
            res.data.results[0].tags ? res.data.results[0].tags: ''
        });
    const latestStories =
        res.data.results &&
        res.data.results.length > 0 &&
        res.data.results[0].images_thumbnails.length > 0 &&
        res.data.results[0].images_thumbnails.map((item) => {
 
        return {
            content: localStorage.accessToken ?  item.caption : item.title,
            id: item.id,
            image: item.image,
            title: item.title,
            slug:'/detail/'+item.slug
        }});
    setLatestData(latestStories)
    })
 }
 const formatDate = (timestamp)=>{
    const date = parseISO(timestamp);
    return format(date, 'MMMM dd, yyyy h:mm a');
 }


  useEffect(() => {
    if(localStorage && localStorage.length > 0 && localStorage.accessToken){
       setLogin(true)
    }
    LatestData();
    
 }, [newsId])
    return(
        <>
         <Header />
        	<section className="position-relative gen-section-padding-3">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-12 align-self-center">
                            <div className="news-cat">
                                <h2>
                                 {collectionData && collectionData.tags && collectionData.tags.length > 0 && collectionData.tags.map((tgg,t)=>{
                                        return(
                                            <span key={t}>
                                              {tgg.name} 
                                            </span>
                                        )
                                    })}
                                    
                                </h2>
                            </div> 
                            <div className="gen-single-tv-show-info event-info">
                                <h2 className="gen-title">{collectionData.title}</h2>
                                <p><b>{collectionData.dateTime} </b></p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="pm-inner">
                                <div className="gen-more-like">
                                    <div className="row post-loadmore-wrapper">
                                   {latData && latData.length > 0 &&  latData.map((imgArrry, i)=>{
                                    return(
                                        <div className="col-xl-4 col-lg-4 col-md-6 mb-3" key={i}>
                                        <NavLink to={imgArrry.slug} title={imgArrry.content}>
                                            <div className="thumb w-100  album-thumb p-4">
                                                <img className="event-thumb-image w-100" src={login ? imgArrry.image:'https://iansportalimages.s3.amazonaws.com/others-img/default-events-image.png'}/>
                                                {/* <div className="infozoomin">
                                                    <a><i class="fa fa-image" aria-hidden="true"></i></a>
                                                </div> */}
                                                <div className="text-l-gray des-title mt-3">
                                                {imgArrry.title}</div>
                                            </div>
                                        </NavLink>
                                </div>
                                    )

                                   })} 
                                        

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
  
                </div>
	        </section>
            <Footer />
        </>
    )
}
export default Event